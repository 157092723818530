export default {
    // URLs  || Base URLs should end with a forward slash (/)
    baseApiUrl:        process.env.VUE_APP_BASE_API_URL,

    oauth2: {
        clientId:      process.env.VUE_APP_OAUTH2_CLIENT_ID,
        redirectUri:   process.env.VUE_APP_OAUTH2_REDIRECT_URI,
        authEndpoint:  process.env.VUE_APP_OAUTH2_AUTH_ENDPOINT,
        tokenEndpoint: process.env.VUE_APP_OAUTH2_TOKEN_ENDPOINT,
    },

    /**
     * TODO remove this comment when building for the live version
     *
     * DEVELOPMENT ENVIRONMENT
     *
     * baseApiUrl:        'https://troublefree.lan/v3/api/',
     *
     * oauth2: {
     * clientId:          'f005eff514cde2fe206d262e38b2d6f3',
     * redirectUri:       'http://localhost:8080',
     *     authEndpoint:  'https://troublefree.lan/v3/oauth2/auth',
     *     tokenEndpoint: 'https://troublefree.lan/v3/oauth2/token',
     * },
     *
     * TEST ENVIRONMENT
     *
     * baseApiUrl:        'https://tst.dev.troublefree.nl/v3/api/',
     *
     * oauth2: {
     * clientId:          'f005eff514cde2fe206d262e38b2d6f3',
     * redirectUri:       'https://transport-acc.smarttrade.nu',
     *     authEndpoint:  'https://tst.dev.troublefree.nl/v3/oauth2/auth',
     *     tokenEndpoint: 'https://tst.dev.troublefree.nl/v3/oauth2/token',
     * },
     *
     * LIVE ENVIRONMENT
     *
     * baseApiUrl:        'https://smarttrade.troublefree.nl/v3/api/',
     *
     * oauth2: {
     * clientId:          '2c59e23d318d6229ab944ef6430d4ee70df09611611fbc6324bd67af65adf5a0',
     * redirectUri:       'https://transport.smarttrade.nu',
     *     authEndpoint:  'https://smarttrade.troublefree.nl/v3/oauth2/auth',
     *     tokenEndpoint: 'https://smarttrade.troublefree.nl/v3/oauth2/token',
     * },
     */
};
