import axios  from 'axios';
import CONFIG from '../config';

const ACCESS_TOKEN_STORAGE_KEY  = 'api_token_access';
const REFRESH_TOKEN_STORAGE_KEY = 'api_token_refresh';

const apiClient = axios.create({
    baseURL: CONFIG.baseApiUrl,
});

// add a request interceptor to inject the authorization header
apiClient.interceptors.request.use(
    (config) => {
        if (! config.headers['Authorization']) {
            const accessToken = window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

            if (! accessToken) {
                return Promise.reject('No access token provided');
            }

            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor to try refreshing the access token if needed
apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        /** @type {import('axios').AxiosError} error */
        if (error.response) {
            if (error.response.status === 401) {
                const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);

                if (! refreshToken) {
                    throw error;
                }

                const response = await axios.post(
                    CONFIG.oauth2.tokenEndpoint,
                    {
                        grant_type:   'refresh_token',
                        refresh_token: refreshToken,
                        client_id:     CONFIG.oauth2.clientId,
                        redirect_uri:  CONFIG.oauth2.redirectUri,
                    }
                );

                if (response.data.token_type !== 'bearer') {
                    //eslint-disable-next-line no-console
                    console.error(`Expected bearer token but got token_type "${response.data.token_type}"`);
                }

                window.localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY,  response.data.access_token);
                window.localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, response.data.refresh_token);

                error.config.headers['Authorization'] = `Bearer ${response.data.access_token}`;

                return axios.request(error.config);
            }
        }
        throw error;
    }
);

export { apiClient, ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY };
